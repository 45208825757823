import { graphql, useStaticQuery } from 'gatsby';
import React, { ReactElement } from 'react';

// FIXME: TRY NOT TO USE GATSBY STRUCTURE ON REUSABLE COMPONENTS
// TODO: CREATE COMPONENT MODEL
// TODO: Missing Duration and recipeCuisine fields
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RecipeCarousel: React.FC<any> = ({ recipes }): ReactElement => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );
  const data = JSON.stringify({
    '@context': 'https://schema.org/',
    '@type': 'ItemList',
    itemListElement: recipes.map((r, i: number) => ({
      '@type': 'ListItem',
      position: i + 1,
      url: site.siteMetadata.siteUrl + r.node.fields.path,
    })),
  });
  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: data }} />;
};

export default RecipeCarousel;
