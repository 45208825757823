import React, { ReactElement, useState, useEffect } from 'react';
import { PostsDistributiveProps, IEdge } from './posts-distributive.model';
import { ILabel } from '../post-card/post-card.model';
import { ICustomWindow } from '../../models/global.model';
import PostsDistributiveStyles from './posts-distributive.module.scss';
import RecipeCarouselSEO from '../seo/structured-data/recipe-carousel';

import PostCard from '../post-card';
//TODO: Delete lodash
import chunk from 'lodash/chunk';

import '../../utils/normalize.css';
import '../../utils/css/screen.css';

declare let window: ICustomWindow;
const PostsDistributive: React.FC<PostsDistributiveProps> = (props: PostsDistributiveProps): ReactElement => {
  /** Number of posts shown initially */
  const initialPostsToShow = 11;
  /** Number of posts to add on each update */
  const postsToAdd = 11;
  /** Current number of posts shown. It is saved in window in order to keep it when navigating to other page */
  const currentPostsToShow = initialPostsToShow;
  //  const currentPostsToShow = window?.currentPostsToShow || initialPostsToShow; Review bug when nom run build
  /** Numeric counter of the post when they are mapped */
  let postCounter = 0;

  const gridDistribution = [
    'col-12 md_col-6 lg_col-6',
    'col-12 md_col-6 lg_col-6',
    'col-12 md_col-7 lg_col-7',
    'col-12 md_col-5 lg_col-5',
    'col-12 md_col-4 lg_col-4',
    'col-12 md_col-4 lg_col-4',
    'col-12 md_col-4 lg_col-4',
    'col-12 md_col-6 lg_col-6',
    'col-12 md_col-6 lg_col-6',
    'col-12 md_col-5 lg_col-5',
    'col-12 md_col-7 lg_col-7',
  ];

  /** Boolean to indicate if we´re waiting for the animation frame to not reupdate posts */
  let ticking: boolean;

  const [state, setState] = useState({
    currentPostsToShow,
  });

  /** Method to handle user´s scroll over the page in order to update posts */
  const handleScroll = () => {
    if (!ticking) {
      ticking = true;
      requestAnimationFrame(() => updatePosts());
    }
  };
  /** Method to update the shown posts */
  const updatePosts = () => {
    const distanceToBottom = document.documentElement.offsetHeight - (window.scrollY + window.innerHeight);
    if (distanceToBottom < 500) {
      setState({
        ...state,
        currentPostsToShow: state.currentPostsToShow + postsToAdd,
      });
    }
    ticking = false;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    // Unsubscribe on returned function as it will be called on component 'unmount'
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.currentPostsToShow = state.currentPostsToShow;
    };
  });
  return (
    <div className={`${PostsDistributiveStyles.container} row`}>
      <RecipeCarouselSEO recipes={props.posts}></RecipeCarouselSEO>
      {chunk(props.posts.slice(props.startingPost || 0, state.currentPostsToShow), postsToAdd).map(postsChunk =>
        postsChunk.map(({ node }: IEdge) => {
          postCounter++;
          return (
            <PostCard
              key={postCounter}
              linkHref={node.fields.path}
              imageSrc={
                node.with_custom_thumbnail &&
                node.custom_thumbnail &&
                node.custom_thumbnail.childImageSharp &&
                node.custom_thumbnail.childImageSharp.fluid.src
                  ? node.custom_thumbnail.childImageSharp.fluid.src
                  : node.localImage.childImageSharp.fluid.src
              }
              withBackgroundImage={
                (!node.with_custom_thumbnail && node.localImage) ||
                (node.with_custom_thumbnail &&
                  node.custom_thumbnail &&
                  node.custom_thumbnail.childImageSharp &&
                  node.custom_thumbnail.childImageSharp.fluid.src)
              }
              imageTitle={node.title} //TODO: Receive correctly this prop
              avatarSrc={node.chef?.avatar?.childImageSharp ? node.chef.avatar.childImageSharp.fluid.src : ''}
              avatarAriaLabel={node.chef?.alias}
              contentHeadingElement={<h3>{node.title}</h3>}
              postClass={gridDistribution[(postCounter + 12) % 11]}
              cardLabels={((node.categories as ILabel[]) || []).concat(node.difficulty || [])}
              postNumber={postCounter}
            />
          );
        }),
      )}
    </div>
  );
};

export default PostsDistributive;
