import React, { ReactElement, useEffect, useState } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { PostCardsProps } from './post-card.model';
import { ICategory } from '../../models/recipes.model';
import PostCardStyles from './post-card.module.scss';
import { StylesProvider, Card, Avatar, CardMedia, CardContent, CardActions } from '@material-ui/core';
import Chip from '../chip';

const PostCard: React.FC<PostCardsProps> = (props): ReactElement => {
  const [state, setState] = useState({
    cardWidth: 0,
  });

  /** Reference of the container element to handle its width */
  let containerElement: HTMLElement | null = null;

  useEffect(() => {
    setState(state => {
      return {
        ...state,
        cardWidth: containerElement?.offsetWidth || 0,
      };
    });
  }, [containerElement]);

  /**
   * In order to override material ui styles without using !important, we must wrap the content with StylesProvider
   * @see https://material-ui.com/guides/interoperability/#controlling-priority-4
   */
  return (
    <StylesProvider injectFirst>
      <article
        className={props.postClass}
        ref={elem => {
          containerElement = elem;
        }}
      >
        <Card
          className={PostCardStyles.card}
          style={{
            backgroundImage:
              props.withBackgroundImage && props.postNumber != 1
                ? `linear-gradient(234deg, rgba(57, 66, 69, 0.4) , rgba(0, 0, 0, 0.4)), url(${props.imageSrc})`
                : props.postNumber % 2 == 0
                ? `linear-gradient(45deg,var(--theme-color-gradient-orange-start),var(--theme-color-gradient-orange-end))`
                : `linear-gradient(45deg,var(--theme-color-ashBlue-plain),var(--theme-color-iceBlue))`,
          }}
        >
          <AniLink swipe direction="bottom" duration={1} className={PostCardStyles.card_link} to={props.linkHref}>
            <CardContent
              className={state?.cardWidth < 600 ? PostCardStyles.content : PostCardStyles.content_large_card}
            >
              <div className={PostCardStyles.content_heading}>{props.contentHeadingElement}</div>
              {PostCardStyles.content_body && (
                <div className={PostCardStyles.content_body}>
                  {' '}
                  {props.avatarSrc && props.avatarAriaLabel && (
                    <Avatar
                      className={state?.cardWidth < 600 ? PostCardStyles.avatar : PostCardStyles.avatar_large_card}
                      src={props.avatarSrc}
                      aria-label={props.avatarAriaLabel}
                      alt={props.imageTitle}
                    />
                  )}
                  {props.avatarSrc && props.avatarAriaLabel && <span>by {props.avatarAriaLabel}</span>}
                </div>
              )}
            </CardContent>
          </AniLink>
          {props.cardLabels && props.cardLabels.length > 0 && (
            <CardActions className={PostCardStyles.labels_container} disableSpacing={true}>
              <AniLink fade duration={1.2} to={props.cardLabels[0].path} className={PostCardStyles.primary_tag}>
                {props.cardLabels[0].name}
              </AniLink>
              <span className={PostCardStyles.difficulty_tag}>
                {props.cardLabels[props.cardLabels.length - 1].name}
              </span>
            </CardActions>
          )}
        </Card>
      </article>
    </StylesProvider>
  );
};

export default PostCard;
